import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout";


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark

  
  return (
    <>
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta name="robots" content="noindex"></meta>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
     
<section class="relative py-4">
<div class="container mx-auto">
<h1 class="font-serif w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" >{frontmatter.title}</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto skBrown w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div> 



  
<div dangerouslySetInnerHTML={{ __html: html }}/>

</div>


</section>

     
     </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`